<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>
                        <template v-if="currentFilter">
                            {{ currentFilter.name }}
                        </template>
                        <template v-else>
                            {{ $t('warehouse.plural') }}
                        </template>
                    </h1>
                </div>
            </div>
            <v-row>
                <v-col>
                    <v-card class="w-100 d-flex flex-column" style="height: 100%">
                        <v-card-title>
                            {{ $t('warehouse.process') }}
                        </v-card-title>
                        <v-card-text class="process__wrap flex-grow-1">
                            <table class="process">
                                <tr>
                                    <td>
                                        <RouterLink :to="{name: 'incoming'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 42C18.6569 42 20 40.6569 20 39C20 37.3431 18.6569 36 17 36C15.3431 36 14 37.3431 14 39C14 40.6569 15.3431 42 17 42Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 2H7.416C8.34381 2.0004 9.14953 2.63884 9.362 3.542L17 36H25" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M44.968 23.094L47.122 16.632C47.325 16.0224 47.2228 15.3523 46.8472 14.831C46.4717 14.3097 45.8685 14.0005 45.226 14H11.824" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.588 30H24.95" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28 38C28 43.5228 32.4772 48 38 48C43.5228 48 48 43.5228 48 38C48 32.4772 43.5228 28 38 28C32.4772 28 28 32.4772 28 38Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M42 38L34 38" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M38 34L42 38L38 42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('incoming.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'buy.invoice_outgoing'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 42C18.6569 42 20 40.6569 20 39C20 37.3431 18.6569 36 17 36C15.3431 36 14 37.3431 14 39C14 40.6569 15.3431 42 17 42Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 2H7.416C8.34381 2.0004 9.14953 2.63884 9.362 3.542L17 36H25" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M44.968 23.094L47.122 16.632C47.325 16.0224 47.2228 15.3523 46.8472 14.831C46.4717 14.3097 45.8685 14.0005 45.226 14H11.824" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.588 30H24.95" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28 38C28 43.5228 32.4772 48 38 48C43.5228 48 48 43.5228 48 38C48 32.4772 43.5228 28 38 28C32.4772 28 28 32.4772 28 38Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M42 38L34 38" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M38 34L42 38L38 42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('delivery.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'buy.invoice_outgoing'}" class="process__step">
                                            <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.4167 24.648L26.6667 24.648L26.6667 20.5647C26.6667 19.923 27.0377 19.769 27.4916 20.2228L34.0086 26.7387C34.2277 26.9575 34.3507 27.2544 34.3507 27.5641C34.3507 27.8737 34.2277 28.1707 34.0086 28.3895L27.4916 34.9053C27.0377 35.3592 26.6667 35.2063 26.6667 34.5647L26.6667 30.4813L21.4167 30.4813" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M19.0834 24.648L16.1667 24.648" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M19.0834 30.4813L16.1667 30.4813" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13.8335 24.648L12.0835 24.648" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13.8335 30.4813L12.0835 30.4813" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.75008 24.648L9.16675 24.648" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.75008 30.4813L9.16675 30.4813" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8.54545 36.9091H4.18182C2.97683 36.9091 2 35.9323 2 34.7273V4.18182C2 2.97683 2.97683 2 4.18182 2H28.1818C29.3868 2 30.3636 2.97683 30.3636 4.18182V8.54545" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.0908 34.5V47C15.0908 47.5523 15.5385 48 16.0908 48H42.4545C43.0067 48 43.4545 47.5523 43.4545 47V15.0909C43.4545 14.5386 43.0067 14.0909 42.4545 14.0909H16.0908C15.5385 14.0909 15.0908 14.5386 15.0908 15.0909V19" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{ $t('good_movement.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'nomenclature'}" class="process__step">
                                            <svg width="47" height="52" viewBox="0 0 47 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5974 13.7224C26.5975 14.0983 26.3946 14.4452 26.0663 14.6305C25.738 14.8158 25.335 14.8109 25.0113 14.6177L21.3594 12.4348L17.7074 14.6177C17.3837 14.8109 16.9807 14.8158 16.6524 14.6305C16.3241 14.4452 16.1213 14.0983 16.1213 13.7224V2H26.5974V13.7224Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M10.8831 37.4783H3.54981C2.39265 37.4783 1.45459 36.5439 1.45459 35.3913V4.08696C1.45459 2.93436 2.39265 2 3.54981 2H39.1685C40.3256 2 41.2637 2.93436 41.2637 4.08696V15.5652" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M37.0964 42.2135L45.4542 49.9999" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.6923 45.2543C35.9245 45.2543 41.7874 39.4146 41.7874 32.2108C41.7874 25.0071 35.9245 19.1674 28.6923 19.1674C21.46 19.1674 15.5972 25.0071 15.5972 32.2108C15.5972 39.4146 21.46 45.2543 28.6923 45.2543Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M22.4067 31.2174V29.1304" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M22.4067 37.4783V35.3913" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M34.9778 37.4783V29.1304" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M28.6924 37.4783V29.1304" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('nomenclature.self') }}</span>
                                        </RouterLink>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><div class="arrow down-right"></div></td>
                                    <td></td>
                                    <td><div class="arrow up-right"></div></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
<!--                                        <RouterLink :to="{name: 'buy.supplier_order'}" class="process__step">-->
<!--                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                                <path d="M38 42L34 38L38 34" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path d="M34 38L42 38" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path d="M15.5879 30H24.9499" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path d="M44.9682 23.094L47.1222 16.632C47.3253 16.0224 47.223 15.3523 46.8475 14.831C46.4719 14.3097 45.8688 14.0005 45.2262 14H11.8242" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28 38C28 43.5228 32.4772 48 38 48C43.5228 48 48 43.5228 48 38C48 32.4772 43.5228 28 38 28C32.4772 28 28 32.4772 28 38Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path d="M2 2H7.416C8.34381 2.0004 9.14953 2.63884 9.362 3.542L17 36H25" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 42C18.6569 42 20 40.6569 20 39C20 37.3431 18.6569 36 17 36C15.3431 36 14 37.3431 14 39C14 40.6569 15.3431 42 17 42Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                            </svg>-->
<!--                                            <span>{{ $t('return_of_buying.self') }}</span>-->
<!--                                        </RouterLink>-->
                                    </td>
                                    <td>
<!--                                        <div class="arrow left"></div>-->
                                    </td>
                                    <td>
                                        <RouterLink :to="{name: 'warehouse'}" class="process__step">
                                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.18164 13.6526V41.4477" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M47.8184 13.6526V41.4477" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.818 35.0334H25.9998V50H11.818C11.2155 50 10.7271 49.5214 10.7271 48.931V36.1025C10.7271 35.5121 11.2155 35.0334 11.818 35.0334Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 35.0334H40.1818C40.7843 35.0334 41.2727 35.5121 41.2727 36.1025V48.931C41.2727 49.5214 40.7843 50 40.1818 50H26V35.0334Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3639 20.0668H31.4548C32.0572 20.0668 32.5457 20.5454 32.5457 21.1358V35.0334H17.2729V21.1358C17.2729 20.5454 17.7614 20.0668 18.3639 20.0668V20.0668Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6362 20.0668H28.1817V25.412C28.1817 26.0024 27.6933 26.4811 27.0908 26.4811H22.7271C22.1246 26.4811 21.6362 26.0024 21.6362 25.412V20.0668Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0908 35.0334H21.6363V40.3787C21.6363 40.9691 21.1479 41.4477 20.5454 41.4477H16.1817C15.5792 41.4477 15.0908 40.9691 15.0908 40.3787V35.0334Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3638 35.0334H36.9092V40.3787C36.9092 40.9691 36.4208 41.4477 35.8183 41.4477H31.4547C30.8522 41.4477 30.3638 40.9691 30.3638 40.3787V35.0334Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M48.9091 13.6526C49.5116 13.6526 50 13.1739 50 12.5835V10.1483C50.0006 9.68753 49.6999 9.27826 49.2538 9.13267L26.6546 2.10051C26.2245 1.9665 25.7625 1.9665 25.3324 2.10051L2.77241 9.1455C2.30981 9.28388 1.99542 9.70423 2.00005 10.1782V12.5835C2.00005 13.1739 2.48847 13.6526 3.09096 13.6526H48.9091Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('warehouse.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td>
<!--                                        <div class="arrow"></div>-->
                                    </td>
                                    <td>
<!--                                        <RouterLink :to="{name: 'warehouse.delivering'}" class="process__step">-->
<!--                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                                <path d="M30 20V12H8V37.998L18 38" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path-->
<!--                                                    d="M36 18V7.99999C36 6.89542 35.1046 5.99999 34 5.99999H25.104C24.0439 3.57629 21.6494 2.01013 19.004 2.01013C16.3586 2.01013 13.9641 3.57629 12.904 5.99999H4C2.89543 5.99999 2 6.89542 2 7.99999V42C2 43.1046 2.89543 44 4 44H20"-->
<!--                                                    stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path d="M14 18H24" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path d="M14 24H24" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path d="M14 30H20" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36 48C42.6274 48 48 42.6274 48 36C48 29.3726 42.6274 24 36 24C29.3726 24 24 29.3726 24 36C24 42.6274 29.3726 48 36 48Z" stroke="#898989"-->
<!--                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                                <path d="M41.348 32.51L35.538 40.258C35.2766 40.6056 34.8773 40.8229 34.4435 40.8536C34.0096 40.8843 33.5837 40.7254 33.276 40.418L30.276 37.418" stroke="#898989" stroke-width="2.5"-->
<!--                                                      stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                            </svg>-->
<!--                                            <span>{{ $t('return_of_buying.self') }}</span>-->
<!--                                        </RouterLink>-->
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'sale.accounting.transfers_outgoing'}" class="process__step">
                                            <svg width="37" height="52" viewBox="0 0 37 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C2 2.44771 2.44772 2 3 2H34C34.5523 2 35 2.44772 35 3V49C35 49.5523 34.5523 50 34 50H3C2.44771 50 2 49.5523 2 49V3Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26.75 8.26086V10.3478" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M16.4377 41.6522H27.7815" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M10.2419 41.1304C10.5267 41.1304 10.7576 41.364 10.7576 41.6522C10.7576 41.9403 10.5267 42.1739 10.2419 42.1739C9.95717 42.1739 9.72632 41.9403 9.72632 41.6522C9.72632 41.364 9.95717 41.1304 10.2419 41.1304" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M10.2419 32.7826C10.5267 32.7826 10.7576 33.0162 10.7576 33.3043C10.7576 33.5925 10.5267 33.8261 10.2419 33.8261C9.95717 33.8261 9.72632 33.5925 9.72632 33.3043C9.72632 33.0162 9.95717 32.7826 10.2419 32.7826" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M18.4917 32.7826C18.7765 32.7826 19.0073 33.0162 19.0073 33.3043C19.0073 33.5925 18.7765 33.8261 18.4917 33.8261C18.2069 33.8261 17.9761 33.5925 17.9761 33.3043C17.9761 33.0162 18.2069 32.7826 18.4917 32.7826" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26.7419 32.7826C27.0267 32.7826 27.2576 33.0162 27.2576 33.3043C27.2576 33.5925 27.0267 33.8261 26.7419 33.8261C26.4572 33.8261 26.2263 33.5925 26.2263 33.3043C26.2263 33.0162 26.4572 32.7826 26.7419 32.7826" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M10.2419 24.4348C10.5267 24.4348 10.7576 24.6684 10.7576 24.9565C10.7576 25.2447 10.5267 25.4783 10.2419 25.4783C9.95717 25.4783 9.72632 25.2447 9.72632 24.9565C9.72632 24.6684 9.95717 24.4348 10.2419 24.4348" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M18.4917 24.4348C18.7765 24.4348 19.0073 24.6684 19.0073 24.9565C19.0073 25.2447 18.7765 25.4783 18.4917 25.4783C18.2069 25.4783 17.9761 25.2447 17.9761 24.9565C17.9761 24.6684 18.2069 24.4348 18.4917 24.4348" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26.7419 24.4348C27.0267 24.4348 27.2576 24.6684 27.2576 24.9565C27.2576 25.2447 27.0267 25.4783 26.7419 25.4783C26.4572 25.4783 26.2263 25.2447 26.2263 24.9565C26.2263 24.6684 26.4572 24.4348 26.7419 24.4348" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 16.6087H35" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{$t('inventarization.self')}}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'warehouse.price_list'}" class="process__step">
                                            <svg width="52" height="46" viewBox="0 0 52 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.6958 6.20001H50.0001" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M18.6958 23H50.0001" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M18.6958 39.8H50.0001" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C2 2.44772 2.44772 2 3 2H9.34783C9.90011 2 10.3478 2.44772 10.3478 3V9.4C10.3478 9.95228 9.90011 10.4 9.34783 10.4H3C2.44771 10.4 2 9.95228 2 9.4V3Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 19.8C2 19.2477 2.44772 18.8 3 18.8H9.34783C9.90011 18.8 10.3478 19.2477 10.3478 19.8V26.2C10.3478 26.7523 9.90011 27.2 9.34783 27.2H3C2.44771 27.2 2 26.7523 2 26.2V19.8Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 36.6C2 36.0477 2.44772 35.6 3 35.6H9.34783C9.90011 35.6 10.3478 36.0477 10.3478 36.6V43C10.3478 43.5523 9.90011 44 9.34783 44H3C2.44771 44 2 43.5523 2 43V36.6Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{$t('price_list.self')}}</span>
                                        </RouterLink>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><div class="arrow up-right"></div></td>
                                    <td></td>
                                    <td><div class="arrow down-right"></div></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>
                                        <RouterLink :to="{name: 'realization_returning'}" class="process__step">
                                            <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.036 36.124C36.6571 35.4 36.999 34.4779 37 33.524V12H11V42H29.16C30.3285 41.9995 31.4384 41.488 32.198 40.6L36.036 36.124Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M41 5.99999H30.104C29.0439 3.57629 26.6494 2.01013 24.004 2.01013C21.3586 2.01013 18.9641 3.57629 17.904 5.99999H7C5.89543 5.99999 5 6.89542 5 7.99999V46C5 47.1046 5.89543 48 7 48H41C42.1046 48 43 47.1046 43 46V7.99999C43 6.89542 42.1046 5.99999 41 5.99999Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 18H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 26H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26 34H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{$t('realization_returning.self')}}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'accounting.transfer_outgoing'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M30.032 19.948V11.948H8.032V37.946L18.032 37.948" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path
                                                    d="M36.032 17.948V7.948C36.032 6.84343 35.1366 5.948 34.032 5.948H25.136C24.0759 3.5243 21.6814 1.95814 19.036 1.95814C16.3906 1.95814 13.9961 3.5243 12.936 5.948H4.032C2.92743 5.948 2.032 6.84343 2.032 7.948V41.948C2.032 43.0526 2.92743 43.948 4.032 43.948H20.032"
                                                    stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14.032 17.948H24.032" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14.032 23.948H24.032" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14.032 29.948H20.032" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M36.032 47.948C42.6594 47.948 48.032 42.5754 48.032 35.948C48.032 29.3206 42.6594 23.948 36.032 23.948C29.4046 23.948 24.032 29.3206 24.032 35.948C24.032 42.5754 29.4046 47.948 36.032 47.948Z"
                                                      stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M27.546 44.432L44.518 27.462" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{$t('receipt_of_good.self')}}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'buy.invoice_outgoing'}" class="process__step">
                                            <svg width="45" height="52" viewBox="0 0 45 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.54545 36.9091H4.18182C2.97683 36.9091 2 35.9323 2 34.7273V4.18182C2 2.97683 2.97683 2 4.18182 2H28.1818C29.3868 2 30.3636 2.97683 30.3636 4.18182V8.54545" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.0909 43.4545H10.7272C9.52224 43.4545 8.54541 42.4777 8.54541 41.2727V10.7273C8.54541 9.52227 9.52224 8.54544 10.7272 8.54544H34.7272C35.9322 8.54544 36.909 9.52227 36.909 10.7273V15.0909" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0908 16.0909C15.0908 15.5386 15.5385 15.0909 16.0908 15.0909H42.4545C43.0067 15.0909 43.4545 15.5386 43.4545 16.0909V49C43.4545 49.5523 43.0067 50 42.4545 50H16.0908C15.5385 50 15.0908 49.5523 15.0908 49V16.0909Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M20 23H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M20 35H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M20 29H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M20 41H35" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{ $t('recording_inventory_transactions.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'warehouse.dashboard'}" class="process__step">
                                            <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 44.008H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M24 40.008V44.008" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 32.008H48" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 28.008C23.5228 28.008 28 23.5309 28 18.008C28 12.4852 23.5228 8.008 18 8.008C12.4772 8.008 8 12.4852 8 18.008C8 23.5309 12.4772 28.008 18 28.008Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M23.654 9.76L18 18.008L24.41 25.68" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M18 18.008H8" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M34 10.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M34 16.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M34 22.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5.508C2 4.67958 2.67157 4.008 3.5 4.008H46.5C47.3284 4.008 48 4.67958 48 5.508V38.508C48 39.3364 47.3284 40.008 46.5 40.008H3.5C2.67157 40.008 2 39.3364 2 38.508V5.508Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{ $t('reports') }}</span>
                                        </RouterLink>
                                    </td>
                                </tr>
                            </table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import FilterView from "@/plugins/mixins/FilterView";
import ResourceView from "@/plugins/mixins/ResourceView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Side from "@/components/Warehouse/Side.vue";
import NavMixin from "@/plugins/mixins/Nav";

export default {
    name: "Warehouses",
    directives: {
        mask,
    },
    components: {
        PageLayout,
        Side
    },
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    data() {
        return {
            entityName: 'warehouse',
            invalid: false,
            items: [],
            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            options: {
                ...FilterView.data().options,
                itemsPerPage: 10,
                sortBy: [
                    "id"
                ],
            },
            loading: false
        };
    },

    computed: {
        ...mapGetters(['userBlock', 'lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.getItems();
            this.getFilters();
        },

        updateKey(oldVal, head) {
            if (oldVal) {
                const oldKey = oldVal;
                const newKey = head.field;
                if (oldKey === newKey) {
                    return
                }
                for (let i = 0; i < this.tableData.length; i++) {
                    Object.entries(this.tableData[i]).forEach(([key, item]) => {
                        if (key === oldKey) {
                            this.tableData[i][newKey] = this.tableData[i][key]
                            delete this.tableData[i][oldKey];
                            head.value = newKey;
                            head.show = newKey;
                        }
                    });
                }
                const foundObject = this.headerOptions.find(obj => obj.value === newKey);
                if (foundObject) {
                    foundObject.disabled = true
                }
            }
        },

        handleSuccess({results, header}) {
            this.tableData = results
            for (let i = 0; i < header.length; i++) {
                this.tableHeader.push(
                    {
                        text: header[i],
                        align: "left",
                        sortable: false,
                        value: header[i],
                        field: 'column_' + i,
                        field_clear: 'column_' + i,

                    }
                );
            }
        },

        async deleteItem(item) {
            if (confirm(this.$t('warehouse.delete'))) {
                var _this = this
                this.loading = true;

                await this.$http
                    .delete(`admin/warehouse/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('warehouse.has_been_deleted'))
                        this.getItems()
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('warehouse.has_not_been_deleted'))
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async restoreItem(item) {
            if (confirm(this.$t('warehouse.restore'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .post(`admin/warehouse/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('warehouse.has_been_restored'))
                        this.getItems()
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('warehouse.has_not_been_restored'))
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async getItems() {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;

            let params = {};

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            if (sortBy !== undefined && sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            } else {
                params.sortBy = 'id';
            }

            if (sortDesc !== undefined && sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            } else {
                params.sortDir = 'asc';
            }

            if (page !== undefined) {
                params.page = page
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            } else {
                params.perPage = this.itemsPerPage;
            }

            await this.$http
                .get("admin/warehouse/warehouse", {
                    params: params,
                })
                .then(res => {
                    this.items = res.body.data
                    if (this.page > res.body.meta.last_page) {
                        this.onPageChange(res.body.meta.last_page);
                    }
                    this.totalUveds = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.items = []
                    this.totalUveds = 0
                    this.$toastr.error(this.$t('failed_to_get_list_uveds'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
